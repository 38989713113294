@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
:root {
  --primary-color: #ff7e67;
  --secondary-color: rgba(255, 126, 103, 0.12);
}

*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(255, 126, 103, 0.1);
  padding: 0.75rem;
  border-radius: 99px;
}
.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
  outline: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  margin: 0;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.75rem;
  border-radius: 50%;
  background-color: var(--secondary-color);
  transition: 0.15s ease-in;
}

input[type="radio"]:checked + label {
  color: var(--primary-color);
}
input[type="radio"]:checked + label > .notification {
  background-color: var(--primary-color);
  color: #fff;
}

.glider {
  position: absolute;
  display: flex;
  height: 54px;
  background-color: var(--secondary-color);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

input:focus {
  -webkit-tap-highlight-color: transparent;
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}

@media (min-width: 700px) {
  .tabs {
    transform: scale(0.8);
  }
}
